import { useMemo } from 'react'

import type { authorization_authorization$key } from '__generated__/authorization_authorization.graphql'
import type { authorization_content$key } from '__generated__/authorization_content.graphql'
import type { ContentPieceSection_contentPieceSelection } from '__generated__/ContentPieceSection_contentPieceSelection.graphql'
import type { SECTION_STYLE } from 'modules/ContentSection/consts'
import { SECTION_STYLE_PRIMARY, SECTION_STYLE_SECONDARY } from 'modules/ContentSection/consts'
import ContentPieceCarouselSection from 'modules/ContentSection/ContentPieceCarouselSection'
import ContentPiecePrimarySection from 'modules/ContentSection/ContentPiecePrimarySection'
import ContentPieceSecondarySection from 'modules/ContentSection/ContentPieceSecondarySection'
import type { RelatedContentPiecesList } from 'modules/RelatedContentPieces/RelatedContentPieces'

type ContentPieceList = ContentPieceSection_contentPieceSelection['contentPieceList']

type ContentSectionProps = {
    title: string
    subtitle?: string
    readMore?: {
        text: string
        url: string
    } | null
    sectionStyle?: SECTION_STYLE
    cardStyle?: string
    isAboveTheFold?: boolean
    contentPieceList: ContentPieceList | RelatedContentPiecesList | null
    // Fragment key for user authorization data
    authorization: authorization_authorization$key
    // Fragment key for content authorization data
    contentAuthorization: authorization_content$key
}
/* This component contains the logic to decide which section to display
 * based on the section style.
 */

const ContentSection = ({
    title,
    subtitle,
    readMore,
    sectionStyle,
    isAboveTheFold,
    contentPieceList,
    cardStyle,
    authorization,
    contentAuthorization,
}: ContentSectionProps) => {
    const extractedList = useMemo(() => {
        if (!contentPieceList) return null

        if (!('edges' in contentPieceList)) {
            // Annoying fix because ContentPieceInterface doesn't use a
            // connection and it could be a big refactor
            return {
                edges: contentPieceList.map(contentPiece => {
                    return {
                        node: contentPiece,
                    }
                }),
            }
        } else {
            return contentPieceList
        }
    }, [contentPieceList])

    if (!extractedList) return null

    if (sectionStyle === SECTION_STYLE_PRIMARY) {
        if (!subtitle) return null
        return (
            <ContentPiecePrimarySection
                title={title}
                subtitle={subtitle}
                readMore={readMore}
                authorization={authorization}
                contentAuthorization={contentAuthorization}
                contentPieceList={extractedList}
                isAboveTheFold={isAboveTheFold}
                showMostRead={false} // TODO: FIN-1272
            />
        )
    }

    if (sectionStyle === SECTION_STYLE_SECONDARY) {
        return (
            <ContentPieceSecondarySection
                title={title}
                isAboveTheFold={isAboveTheFold}
                contentPieceList={extractedList}
                authorization={authorization}
                contentAuthorization={contentAuthorization}
            />
        )
    }

    // Default to Carousel
    return (
        <ContentPieceCarouselSection
            title={title}
            subtitle={subtitle}
            cardStyle={cardStyle}
            authorization={authorization}
            contentAuthorization={contentAuthorization}
            contentPieceList={extractedList}
            isAboveTheFold={isAboveTheFold}
        />
    )
}

export default ContentSection
