import React from 'react'

import styled from 'styled-components'

import LargeHeader from './LargeHeader'
import ReadMore from './ReadMore'
import { ResponsiveSectionColumn, SectionColumn } from './SectionColumn'

import Divider from 'components/Divider'
import Box from 'components/Primitives/Box'

type PrimarySectionProps = {
    title: string
    subtitle: string
    readMore?: {
        text: string
        url: string
    } | null
    largeCard: React.ReactNode
    /* three medium cards */
    middleMediumCards: React.ReactNode[]
    /* three medium cards */
    rightMediumCards: React.ReactNode[]
}

// UI Layout component used for latest news and analysis sections

const PrimarySection = (props: PrimarySectionProps) => {
    const { title, subtitle, readMore, largeCard, middleMediumCards, rightMediumCards } = props

    return (
        <Box p={0} pt={1} pb="2.75rem">
            <HeaderContainer>
                <Box>
                    <LargeHeader title={title} subtitle={subtitle} />
                </Box>
                {readMore && <ReadMore url={readMore.url} text={readMore.text} />}
            </HeaderContainer>

            <WrappingContainer>
                {/* first column: one large item */}
                <Box minWidth={300} flex={[1, 1, '0%']}>
                    <Box>{largeCard}</Box>
                </Box>
                {/* second column: 3 medium items */}
                <SectionColumn>
                    {middleMediumCards.map((mediumCard, index) => (
                        <Box
                            key={index} // index + offset?
                        >
                            <DivideWrap className={index === 0 ? 'first-divider' : ''}>
                                <Divider />
                            </DivideWrap>
                            {mediumCard}
                        </Box>
                    ))}
                </SectionColumn>
                {/* third column: 3 medium items or most read module */}
                {
                    <ResponsiveSectionColumn>
                        {rightMediumCards.map((mediumCard, index) => (
                            <Box
                                key={index} // index + offset?
                            >
                                <DivideWrap className={index === 0 ? 'first-divider-col2' : ''}>
                                    <Divider />
                                </DivideWrap>
                                {mediumCard}
                            </Box>
                        ))}
                    </ResponsiveSectionColumn>
                }
            </WrappingContainer>
        </Box>
    )
}

const HeaderContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`


const WrappingContainer = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem;
    border-top: 1px solid;
    border-color: ${({ theme }) => theme.palette.neutral[999]};
    padding-top: 1.25rem;
`
const DivideWrap = styled(Box)`
    width: 100%;
    padding-left: 0.75rem;
    &.first-divider {
        @media (min-width: ${({ theme }) => theme.layout.breakpoints.medium}) {
            display: none;
        }
    }
    &.first-divider-col2 {
        @media (min-width: ${({ theme }) => theme.layout.breakpoints.large}) {
            display: none;
        }
    }
`

export default PrimarySection
