import type { ReactElement } from 'react'

import LargeHeader from './LargeHeader'

import Carousel from 'components/Carousel'
import type CarouselCard from 'components/ContentCards/CarouselCard/CarouselCard'
import type CarouselHighlightCard from 'components/ContentCards/CarouselHighlightCard/CarouselHighlightCard'
import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'

export const CAROUSEL_CONTENT_CARD_WIDTH = 272
export const CAROUSEL_CONTENT_CARD_HEIGHT = 380

type Props = {
    title: string
    subtitle?: string
    // Because of the specific height & width, we should only be passing cards which are optimised for a carousel here.
    children: ReactElement<typeof CarouselHighlightCard | typeof CarouselCard>[]
} & BoxProps

// Carousel UI component used to show CarouselHighlightCards and CarouselCards

const CarouselSection = (props: Props) => {
    const { title, subtitle, children, ...containerStyleProps } = props

    return (
        <Box p={0} pt={1} pb="1.25rem" {...containerStyleProps}>
            <LargeHeader title={title} subtitle={subtitle} />

            <Carousel
                ariaLabel={`${title} carousel content`}
                itemWidth={CAROUSEL_CONTENT_CARD_WIDTH}
                itemHeight={CAROUSEL_CONTENT_CARD_HEIGHT}
            >
                {children}
            </Carousel>
        </Box>
    )
}

export default CarouselSection
