import Heading from 'components/Heading'
import { fonts } from 'theme'

type Props = {
    title: string
}

const SmallHeader = (props: Props) => {
    const { title } = props
    return (
        <>
            <Heading
                as="h2"
                fontStyle="italic"
                fontSize={fonts.size.S}
                fontWeight={fonts.weight.extraBold}
                lineHeight={fonts.lineHeight.heading}
                paddingBottom="1rem"
                marginY={0}
            >
                {title.toUpperCase()}
            </Heading>
        </>
    )
}

export default SmallHeader
