import styled from 'styled-components'

import Icon from 'components/Icon'
import Link from 'components/Link'
import { LegacyLink } from 'components/Link'
import Paragraph from 'components/Paragraph'
import Box from 'components/Primitives/Box'
import { fonts, trispace } from 'theme'

type Props = {
    url: string
    text: string
}

const ReadMore = (props: Props) => {
    const { url, text } = props
    const urlObject = new URL(url)
    return (
        <ReadMoreContainer>
            <StyledLink url={urlObject}>
                {/* Using LegacyLink here because the underline hover state does not work otherwise */}
                <Paragraph>{text}</Paragraph>
            </StyledLink>
            <Link url={urlObject}>
                <Icon
                    icon="RiArrowRightLine"
                    marginLeft="0.35rem"
                    marginTop="0.35rem"
                    color="neutral.999"
                />
            </Link>
        </ReadMoreContainer>
    )
}

const StyledLink = styled(LegacyLink)`
    font-size: ${fonts.size.XXS};
    font-weight: ${fonts.weight.normal};
    color: ${({ theme }) => theme.palette.neutral[999]};
    font-family: ${trispace.style.fontFamily};
    & > p {
        &:hover {
            text-decoration: underline;
        }
    }
`

const ReadMoreContainer = styled(Box)`
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-left: 1rem;
    text-align: right;
`

export default ReadMore

