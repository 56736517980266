import { useRouter } from 'next/router'

export type QueryParams = {
    [key: string]: string | undefined
}

/**
 *
 * @returns string query params (if query is an array, returns the first value)
 * TODO should we replace with useSearchParams?
 */
export const useQueryStrings = (): QueryParams => {
    const { query } = useRouter()

    if (!query) return {}

    const queryStrings = Object.entries(query).reduce((obj, [key, value]) => {
        const pickedParam = Array.isArray(value) ? value[0] : value
        return { ...obj, [key]: pickedParam }
    }, {})

    return queryStrings
}
