import React from 'react'

import Box from 'components/Primitives/Box'
import type { ColorName} from 'theme';
import { palette, getColor } from 'theme'

const Divider = ({ color }: { color?: ColorName }) => (
    <Box
        display="flex"
        borderBottom={`1px solid ${!!color ? getColor(color) : palette.neutral[200]}`}
        width="100%"
    ></Box>
)

export default Divider
