import DetailedPreviewCard from 'components/ContentCards/DetailedPreviewCard'
import type { DetailedPreviewCardProps } from 'components/ContentCards/DetailedPreviewCard/DetailedPreviewCard'

type MediumCardProps = Omit<DetailedPreviewCardProps, 'image' | 'subtitle'>

/**
 *
 * @returns A preview card suitable for use in medium sections. It has a title, tags and meta fields, but no image or subtitle.
 * https://www.figma.com/file/HqUnQfZ4SSCHHAZuI0Ioco/UI-Content-Expansion?type=design&node-id=726-665&mode=design&t=QyDolZqUNxbvmgSX-4
 */
const MediumCard = ({
    title,
    url,
    author,
    metaText,
    tags: Tags,
    color,
    isLocked,
    contentTypePublicName,
    bookmarkButton,
    onClick,
}: MediumCardProps) => {
    return (
        <DetailedPreviewCard
            title={title}
            url={url}
            image={null}
            subtitle={null}
            author={author}
            titleMaxLines={3}
            metaText={metaText}
            tags={Tags}
            color={color}
            isLocked={isLocked}
            contentTypePublicName={contentTypePublicName}
            bookmarkButton={bookmarkButton}
            onClick={onClick}
        />
    )
}

export default MediumCard
