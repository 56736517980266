import DetailedPreviewCard from 'components/ContentCards/DetailedPreviewCard'
import type { DetailedPreviewCardProps } from 'components/ContentCards/DetailedPreviewCard/DetailedPreviewCard'
import { fonts } from 'theme'

type SmallCardProps = Omit<
    DetailedPreviewCardProps,
    'subtitle' | 'image' | 'tags' | 'contentTypePublicName'
>

/**
 *
 * @returns A preview card suitable for use in small sections. It has a title and limited meta fields, but no image, subtitle or tags.
 * https://www.figma.com/file/HqUnQfZ4SSCHHAZuI0Ioco/UI-Content-Expansion?type=design&node-id=726-665&mode=design&t=QyDolZqUNxbvmgSX-4
 */
const SmallCard = ({
    title,
    url,
    author,
    metaText,
    color,
    isLocked,
    bookmarkButton,
    onClick,
}: SmallCardProps) => {
    return (
        <DetailedPreviewCard
            title={title}
            subtitle={null}
            url={url}
            author={author}
            titleMaxLines={3}
            titleSize={fonts.size.S}
            metaText={metaText}
            color={color}
            tags={null}
            isLocked={isLocked}
            image={null}
            contentTypePublicName={null}
            bookmarkButton={bookmarkButton}
            onClick={onClick}
        />
    )
}

export default SmallCard
