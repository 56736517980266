import DetailedPreviewCard from 'components/ContentCards/DetailedPreviewCard'
import type { DetailedPreviewCardProps } from 'components/ContentCards/DetailedPreviewCard/DetailedPreviewCard'

type LargeCardProps = DetailedPreviewCardProps

/**
 *
 * @returns A preview card suitable for use in large sections. It has a title, subtitle, image and all meta fields.
 * https://www.figma.com/file/HqUnQfZ4SSCHHAZuI0Ioco/UI-Content-Expansion?type=design&node-id=735-4341&mode=design&t=Qj0YU05iSgdoBR2J-4
 */
const LargeCard = ({
    title,
    subtitle,
    url,
    author,
    metaText,
    color,
    tags: Tags,
    image: Image,
    isLocked,
    contentTypePublicName,
    bookmarkButton,
    onClick,
}: LargeCardProps) => {
    return (
        <DetailedPreviewCard
            title={title}
            subtitle={subtitle}
            url={url}
            author={author}
            titleMaxLines={2}
            subtitleMaxLines={3}
            metaText={metaText}
            color={color}
            tags={Tags}
            isLocked={isLocked}
            image={Image}
            contentTypePublicName={contentTypePublicName}
            bookmarkButton={bookmarkButton}
            onClick={onClick}
        />
    )
}

export default LargeCard
