import type { ReactElement } from 'react'

import styled from 'styled-components'

export type CarouselItemWrapperProps = {
    children: ReactElement
    // Specify the width and/or height of the carousel item. If a number is provided, it will be
    // converted to pixels. If a string is provided, it will be used as is.
    itemWidth?: number | string
    itemHeight?: number | string
    // This is used so we don't show the slides until the carousel is initialised,
    // as this can lead to a content layout shift in some situations.
    visibility?: 'initial' | 'hidden'
}

// These need to be transient props as they are being passed directly into a React node in `styled.div`
type CarouselItemWrapperStyledProps = {
    $itemHeight?: CarouselItemWrapperProps['itemHeight']
    $itemWidth?: CarouselItemWrapperProps['itemWidth']
    visibility?: 'initial' | 'hidden'
}

const CarouselItemWrapper = ({
    itemWidth,
    itemHeight,
    children,
    visibility = 'initial',
}: CarouselItemWrapperProps) => {
    return (
        <CarouselItemWrapperStyled
            $itemWidth={itemWidth}
            $itemHeight={itemHeight}
            visibility={visibility}
        >
            {children}
        </CarouselItemWrapperStyled>
    )
}

const CarouselItemWrapperStyled = styled.div<CarouselItemWrapperStyledProps>`
    visibility: ${({ visibility }) => visibility};
    width: ${({ $itemWidth }) =>
        typeof $itemWidth === 'number' ? `${$itemWidth}px` : $itemWidth || '100%'};
    height: ${({ $itemHeight }) =>
        typeof $itemHeight === 'number' ? `${$itemHeight}px` : $itemHeight || '100%'};
`

export default CarouselItemWrapper
