import Heading from 'components/Heading'
import type { ColorName } from 'theme'
import { fonts } from 'theme'

type Props = {
    title: string
    subtitle?: string
    fontColor?: ColorName
}

const LargeHeader = (props: Props) => {
    const { title, subtitle, fontColor = 'neutral.999' } = props
    return (
        <>
            <Heading
                as="h2"
                fontStyle="italic"
                fontSize={fonts.size.M}
                fontWeight={fonts.weight.extraBold}
                lineHeight={fonts.lineHeight.heading}
                paddingBottom="0.3rem"
                marginY={0}
                color={fontColor}
            >
                {title?.toUpperCase()}
            </Heading>
            {subtitle && (
                <Heading
                    as="h3"
                    fontSize={fonts.size.XS}
                    fontWeight={fonts.weight.normal}
                    lineHeight={fonts.lineHeight.paragraph}
                    paddingBottom="1rem"
                    marginY={0}
                    color={fontColor}
                >
                    {subtitle}
                </Heading>
            )}
        </>
    )
}

export default LargeHeader
