/**
 * Constants for the number of images that appear above the fold on a Macbook 14-inch screen.
 *
 * Using this as standard for image priority for now.
 *
 * We have had to strike a balance between mobile and desktop sizes. Even within desktop a large
 * screen will display a lot more than a laptop screen. The impact of loading too many images is
 * a greater performance hit for mobile than not loading enough on desktop.
 *
 */

// Images that appear above the fold on the feed page, ie news and analysis pages.
export const FEED_PAGES_ROWS_ABOVE_THE_FOLD_IMAGES = 1

// Images that appear on the screen in the carousel with the sidebar open.
export const CAROUSEL_IMAGES_ON_SCREEN = 4
