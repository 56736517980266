import DetailedPreviewCard from 'components/ContentCards/DetailedPreviewCard'
import type { DetailedPreviewCardProps } from 'components/ContentCards/DetailedPreviewCard/DetailedPreviewCard'
import { ImageRatios } from 'consts'
import { fonts } from 'theme'

type CarouselSquareImageCardProps = Omit<
    DetailedPreviewCardProps,
    'subtitle' | 'tags' | 'author' | 'metaText' | 'contentTypePublicName'
>

/**
 *
 * @returns A preview card with a square image suitable for use in a carousel with pack/guide content. It has an image and title.
 */
const CarouselSquareImageCard = ({
    title,
    url,
    isLocked,
    image,
    bookmarkButton,
    color,
    onClick,
}: CarouselSquareImageCardProps) => {
    return (
        <DetailedPreviewCard
            title={title}
            subtitle={null}
            url={url}
            titleMaxLines={3}
            titleSize={fonts.size.S}
            tags={null}
            isLocked={isLocked}
            image={image}
            imageRatio={ImageRatios.SQUARE}
            contentTypePublicName={null}
            bookmarkButton={bookmarkButton}
            color={color}
            onClick={onClick}
        />
    )
}

export default CarouselSquareImageCard
