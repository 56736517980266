import DetailedPreviewCard from 'components/ContentCards/DetailedPreviewCard'
import type { DetailedPreviewCardProps } from 'components/ContentCards/DetailedPreviewCard/DetailedPreviewCard'

type CarouselHighlightCardProps = Omit<DetailedPreviewCardProps, 'subtitle'>

/**
 *
 * @returns A preview card suitable for use in a carousel. It has an image, title, meta fields, but no subtitle.
 * https://www.figma.com/file/HqUnQfZ4SSCHHAZuI0Ioco/UI-Content-Expansion?type=design&node-id=738-4572&mode=design&t=Ai3SH1TLZ8x3dH4A-4
 */
const CarouselHighlightCard = ({
    title,
    url,
    author,
    metaText,
    color,
    tags: Tags,
    isLocked,
    image,
    contentTypePublicName,
    bookmarkButton,
    onClick,
}: CarouselHighlightCardProps) => {
    return (
        <DetailedPreviewCard
            title={title}
            subtitle={null}
            url={url}
            author={author}
            titleMaxLines={4}
            metaText={metaText}
            color={color}
            tags={Tags}
            isLocked={isLocked}
            image={image}
            contentTypePublicName={contentTypePublicName}
            bookmarkButton={bookmarkButton}
            onClick={onClick}
        />
    )
}

export default CarouselHighlightCard
