import styled from 'styled-components'

import Box from 'components/Primitives/Box'

export const SectionColumn = styled(Box)`
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    gap: 1rem;
`

export const ResponsiveSectionColumn = styled(SectionColumn)`
    @media (max-width: ${({ theme }) => theme.layout.breakpoints.medium}) {
        flex-direction: row;
        justify-content: space-between;
        padding; 10px;
    }

    @media (max-width: ${({ theme }) => theme.layout.breakpoints.medium}) {
        flex-direction: column;
    }
`
