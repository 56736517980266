import styled from 'styled-components'

import { ResponsiveSectionColumn, SectionColumn } from './SectionColumn'
import SmallHeader from './SmallHeader'

import Divider from 'components/Divider'
import Box from 'components/Primitives/Box'

type Props = {
    title: string
    subtitle?: string
    largeCard: React.ReactNode
    /* three medium cards */
    mediumCards: React.ReactNode[]
    /* five small cards */
    smallCards: React.ReactNode[]
}

// Layout component used for stock sections

const SecondarySection = (props: Props) => {
    const { title, largeCard, smallCards, mediumCards } = props

    return (
        <Box p={0} pt={2} pb="1.25rem">
            <SmallHeader title={title} />

            <WrappingContainer>
                {/* first column: one item */}
                <Box minWidth={300} flex={[1, 1, '0%']}>
                    <Box>{largeCard}</Box>
                </Box>

                {/* second column: 3 medium items */}
                <ResponsiveSectionColumn>
                    {mediumCards.map((mediumCard, index) => (
                        <Box key={index}>
                            <DivideWrap>
                                <Divider />
                            </DivideWrap>
                            {mediumCard}
                        </Box>
                    ))}
                </ResponsiveSectionColumn>

                {/* third column: 5 small items */}
                <SectionColumn>
                    {smallCards.map((smallCard, index) => (
                        <Box key={index}>
                            <DivideWrap>
                                <Divider />
                            </DivideWrap>
                            {smallCard}
                        </Box>
                    ))}
                </SectionColumn>
            </WrappingContainer>
        </Box>
    )
}

const WrappingContainer = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem;
`

const DivideWrap = styled(Box)`
    width: 100%;
    padding-left: 0.75rem;
`

export default SecondarySection
