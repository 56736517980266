import { useEffect, useCallback, useState } from 'react'

import type { EmblaOptionsType } from 'embla-carousel-react'
import useEmblaCarousel from 'embla-carousel-react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import { transparentize } from 'polished'
import styled from 'styled-components'

import type { ButtonProps } from 'components/Button'
import Button from 'components/Button'
import CarouselItemWrapper, {
    type CarouselItemWrapperProps,
} from 'components/Carousel/CarouselItemWrapper'
import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import type { ColorName, ThemedComponent } from 'theme'
import theme, { getColor, layout, zIndex } from 'theme'
type ScrollCarouselProps = {
    children: React.ReactNode[]
    ariaLabel: string
    // We need to pass this into the FadeWrapper to make sure it's the right colour.
    backgroundColor?: ColorName
    options?: EmblaOptionsType
} & Pick<CarouselItemWrapperProps, 'itemWidth' | 'itemHeight'>
type LocalWrapperProps = {
    isHidden: boolean
    fadeColor: string
}
type FadeWrapperProps = BoxProps & LocalWrapperProps
const ScrollCarousel = ({
    children,
    ariaLabel,
    itemWidth,
    itemHeight,
    backgroundColor,
    options,
}: ScrollCarouselProps) => {
    const [carouselRef, carouselApi] = useEmblaCarousel(
        {
            loop: false,
            containScroll: 'trimSnaps', // trimSnaps removes whitespace from the left and right
            dragFree: true,
            ...options,
        },
        [WheelGesturesPlugin()],
    )
    const [isPrevBtnEnabled, setPrevBtnEnabled] = useState(false)
    const [isNextBtnEnabled, setNextBtnEnabled] = useState(false)

    const scrollPrev = useCallback(() => carouselApi && carouselApi.scrollPrev(), [carouselApi])
    const scrollNext = useCallback(() => carouselApi && carouselApi.scrollNext(), [carouselApi])
    const onSelect = useCallback(() => {
        if (!carouselApi) return
        setPrevBtnEnabled(carouselApi.canScrollPrev())
        setNextBtnEnabled(carouselApi.canScrollNext())
    }, [carouselApi])
    const onResize = useCallback(() => {
        if (!carouselApi) return
        setPrevBtnEnabled(carouselApi.canScrollPrev())
        setNextBtnEnabled(carouselApi.canScrollNext())
    }, [carouselApi])
    useEffect(() => {
        if (!carouselApi) return
        onResize()
        onSelect()

        carouselApi.on('select', onSelect)
        carouselApi.on('resize', onResize)
    }, [carouselApi, onSelect, onResize])

    if (children.length < 1) return null
    const fadeColor = getColor(backgroundColor) || theme.palette.neutral[0]
    return (
        <Root aria-roledescription="carousel">
            <_Carousel ref={carouselRef} aria-label={ariaLabel}>
                <ViewPort aria-atomic={false} aria-live="polite" padding="0.25rem">
                    {children.map((slide: any, index: number) => (
                        <Slide
                            key={index}
                            aria-roledescription="slide"
                            role="group"
                            aria-label={`${index + 1} of ${children.length}`}
                        >
                            <CarouselItemWrapper itemWidth={itemWidth} itemHeight={itemHeight}>
                                {slide}
                            </CarouselItemWrapper>
                        </Slide>
                    ))}
                </ViewPort>
            </_Carousel>
            <FadeWrapperLeft isHidden={!isPrevBtnEnabled} fadeColor={fadeColor}>
                <PrevButton
                    ariaLabel="previous slide"
                    onClick={scrollPrev}
                    disabled={!isPrevBtnEnabled}
                    icon="RiArrowLeftLine"
                    variant="secondary"
                    size="small"
                />
            </FadeWrapperLeft>
            <FadeWrapperRight isHidden={!isNextBtnEnabled} fadeColor={fadeColor}>
                <NextButton
                    ariaLabel="next slide"
                    onClick={scrollNext}
                    disabled={!isNextBtnEnabled}
                    icon="RiArrowRightLine"
                    variant="secondary"
                    size="small"
                />
            </FadeWrapperRight>
        </Root>
    )
}
type StyledBoxProps = ThemedComponent<FadeWrapperProps>
type StyledButtonProps = ThemedComponent<ButtonProps>
const Root = styled.div`
    overflow: hidden;
    position: relative;
`
const _Carousel = styled(Box)`
    height: auto;
    position: relative;
`
const ViewPort = styled(Box)`
    display: flex;
    gap: 1.2rem;
    height: 100%;
    z-index: ${zIndex.layer01};
`
const Slide = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    padding: 0;
    position: relative;
`
const FadeWrapperLeft = styled(Box)<StyledBoxProps>`
    visibility: ${({ isHidden }: StyledBoxProps) => (isHidden ? 'hidden' : 'visible')};
    position: absolute;
    left: -2px;
    top: 0;
    width: 5rem;
    height: 100%;
    background: ${({ fadeColor }: StyledBoxProps) =>
        `linear-gradient(90deg, ${fadeColor} 5%, ${transparentize(1, fadeColor)} 80%)`};
    align-items: center;
    justify-content: center;
    @media (max-width: ${layout.breakpoints.small}) {
        visibility: hidden;
    }
`
const PrevButton = styled(Button)<StyledButtonProps>`
    position: absolute;
    left: 1.25rem;
    top: calc(50% - 1rem);
`
const FadeWrapperRight = styled(Box)<StyledBoxProps>`
    visibility: ${({ isHidden }: StyledBoxProps) => (isHidden ? 'hidden' : 'visible')};
    position: absolute;
    right: -2px;
    top: 0;
    width: 5rem;
    height: 100%;
    background: ${({ fadeColor }: StyledBoxProps) =>
        `linear-gradient(90deg, ${transparentize(1, fadeColor)} 20%, ${fadeColor} 95%)`};
    align-items: center;
    justify-content: center;
    @media (max-width: ${layout.breakpoints.small}) {
        visibility: hidden;
    }
`
const NextButton = styled(Button)`
    position: absolute;
    right: 1.25rem;
    top: calc(50% - 1rem);
`
export default ScrollCarousel
